import { toast } from "react-toastify";
import { searchCandidates } from "../resume/searchCandidates";

export const candidatesSearch = async (
  searchValue,
  setSearch,
  setFilteredData,
  setCandidates,
  setTotal,
  selectedTag,
  searchKeys,
  allCandidatesData,
  size
) => {
  if (searchValue.trim() || selectedTag) {
    try {
      const searchText = searchValue.trim();
      const res = await searchCandidates(
        searchText,
        searchKeys.join(","),
        0,
        1000000,
        selectedTag
      );

      console.log("Search response:", JSON.stringify(res, null, 2));

      if (res && res.status === "SUCCESS" && res.data) {
        if (res.data.total > 0 && Array.isArray(res.data.data)) {
          setFilteredData(res.data.data);
          setTotal(res.data.total);
          setSearch(true);
          toast.success(`Found ${res.data.total} search results`, 5000);
        } else {
          setFilteredData([]);
          setTotal(0);
          setSearch(true);
          toast.info("No results found", 5000);
        }
      } else {
        console.error("Unexpected response structure:", res);
        setFilteredData([]);
        setTotal(0);
        setSearch(true);
        toast.warning("Unexpected response from server", 5000);
      }
    } catch (error) {
      console.error("Search error:", error);
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred while searching. Please try again.";
      toast.error(errMsg, 8000);
      setFilteredData([]);
      setTotal(0);
      setSearch(true);
    }
  } else {
    setSearch(false);
    setFilteredData([]);
    setCandidates(allCandidatesData?.slice(0, size));
    setTotal(allCandidatesData?.length);
  }
};

import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import "./style.css";

export const CandidatePoolGrid = ({ tableData, colDefs, onGridReady }) => {
  
  return (
    <div className="ag-theme-quartz" style={{ height: "auto" }}>
      <AgGridReact
        rowData={tableData}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        rowClassRules={{
          "row-white": (params) => params.node.rowIndex % 2 === 0,
          "row-gray": (params) => params.node.rowIndex % 2 !== 0,
        }}
        rowHeight={65}
        domLayout="autoHeight" 
      />
    </div>
  );
};

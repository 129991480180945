import HTTP from "../../../backendApi";

export const searchCandidates = async (
  text,
  keys,
  page = 0,
  size = 1000000,
  tags = ""
) => {
  let url = `/candidates/search?page=${page}&size=${size}&keys=${keys}`;

  if (text) {
    url += `&text=${encodeURIComponent(text)}`;
  }

  if (tags) {
    url += `&tags=${encodeURIComponent(tags)}`;
  }

  try {
    const response = await HTTP.get(url);

    console.log("API Response:", response.data);

    return response.data;
  } catch (error) {
    console.error("API Error:", error.response || error);
    throw error;
  }
};

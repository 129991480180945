import React, { useEffect, useRef, useState } from "react";
import CommonDrawer from "../commonComponents/CommonDrawer";
import CandidateDetails from "../Interviews/EmployerDashboard/sidebarPages/CandidateDetails";
import CommonDialog from "../commonComponents/CommonDialog";
import DeleteDialogContent from "../commonComponents/DeleteDialogContent";
import { useSelector } from "react-redux";
import download from "../../assets/icons/Profile/download.png";
import threeDot from "../../assets/icons/threeDot.png";
import editIcon from "../../assets/icons/edit.png";
import deleteIcon from "../../assets/icons/delete.png";
import eyeIcon from "../../assets/icons/visible.png";
import { toast } from "react-toastify";
import { getBlobData } from "../../functions/api/resume/getBlobData";
import styled from "styled-components";
import ModalHOC from "../Interviews/SeekerDashboard/ModalHOC";
import UpdateCandidate from "../Interviews/EmployerDashboard/UpdateCandidate";
import AgDialog from "./AgDialog";

const ActionRenderer = (props) => {
  const { row, index, candidateTrigger, setCandidateTrigger } =
    props?.data?.extraInfo || {};
  // console.log(row);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const dropdownRef = useRef(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [open, setOpen] = React.useState(false);

  const accessToken = useSelector((state) => state.auth.userData?.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData?.user?.clientCode
  );
  const handleEdit = (row) => {
    setOpenEditModal(true);
    setCandidateData(row);
    setOpenDropdownIndex(-1);
  };
  const handleToggle = () => {
    toggleDrawer("right", true);
    setOpenDropdownIndex(-1);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setOpenDropdownIndex(-1);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);
  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };
  // function to handle delete operation, which need to be passed to confirm delete dialog Comp as props
  const handleDelete = async (id) => {
    try {
      const res = await deleteCandidate(id, accessToken, clientCode);
      if (res) {
        toast.success("Candidate successfully deleted", 5000);
        setCandidateTrigger(!candidateTrigger);
      }
    } catch (error) {
      // Handle network errors or unexpected issues
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    } finally {
      // Close the modal or perform other cleanup tasks
      handleClose();
    }
  };

  // State, function to open and close Drawer
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setOpenDropdownIndex(-1);

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open, candidateData: CandidateDetails });
    // setState({ ...state, [anchor]: open });
  };

  const handleDownload = async (id, name) => {
    console.log(id, name);
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }
      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );
      // Ensure the filename ends with .pdf
      const pdfName = name.endsWith(".pdf") ? name : `${name}.pdf`;

      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", pdfName);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  return (
    <>
      <ModalHOC
        setOpenNewInterviewModal={setOpenEditModal}
        openNewInterviewModal={openEditModal}
        component={
          <UpdateCandidate
            candidate={row}
            handleClose={() => setOpenEditModal(false)}
            setCandidateTrigger={setCandidateTrigger}
            candidateTrigger={candidateTrigger}
          />
        }
      />
      <div align="center" className="tableCell">
        <CommonDrawer
          toggleDrawer={(anchor, open) => toggleDrawer(anchor, open, row)}
          state={state}
          component={
            <CandidateDetails
              candidate={row}
              accessToken={accessToken}
              clientCode={clientCode}
            />
          }
        />

        <CommonDialog
          open={open}
          handleClose={handleClose}
          component={
            <DeleteDialogContent
              text="Candidate Data"
              handleClose={handleClose}
              handleDelete={() => handleDelete(row?.id)}
            />
          }
        />
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            gap: "0.6rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={download}
            style={{
              width: "0.8rem",
              height: "0.8rem",
              cursor: "pointer",
              border: "0.08rem solid grey",
              padding: "0.3rem",
              borderRadius: "0.3rem",
            }}
            onClick={() =>
              handleDownload(row?.resume?.id, row?.resume?.srcFilename)
            }
          />
          <img
            src={threeDot}
            style={{
              width: "0.8rem",
              height: "0.8rem",
              cursor: "pointer",
              border: "0.08rem solid grey",
              padding: "0.3rem",
              borderRadius: "0.3rem",
            }}
            className={`three-dots ${
              openDropdownIndex === index ? "active" : ""
            }`}
            onClick={() => {
              if (openDropdownIndex === index) {
                closeAllDropdowns();
              } else {
                openDropdown(index);
              }
            }}
          />

          <BoxRow>
            <div
              className={`dropdown-content ${
                openDropdownIndex === index ? "open" : ""
              }`}
              ref={dropdownRef}
            >
              <span onClick={toggleDrawer("right", true)}>
                <img src={eyeIcon} className="threeDotIcon" /> View Details
              </span>
              <span onClick={() => handleEdit(row)}>
                <img src={editIcon} className="threeDotIcon" /> Edit Details
              </span>
              <span onClick={handleClickOpen}>
                <img src={deleteIcon} className="threeDotIcon" /> Delete
              </span>
            </div>
          </BoxRow>
        </div>
      </div>
    </>
  );
};

export default ActionRenderer;

const BoxRow = styled.div`
  .three-dots {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: fixed;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    bottom: 8px;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
  }

  .dropdown-content span {
    padding: 0rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown-content span:hover {
    background: #e2dbdb36;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: flex;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { candidatesSearch } from "../../functions/api/employers/Search";

export const Filter = ({
  setFilteredData,
  setCandidates,
  setTotal,
  setSearch,
  allCandidatesData,
  size,
  searchKeys,
  placeholder,
  isYOE,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [filterType, setFilterType] = useState("contains");
  const handleClick = () => {
    if (searchValue !== "") {
      candidatesSearch(
        searchValue,
        setSearch,
        setFilteredData,
        setCandidates,
        setTotal,
        selectedTag,
        searchKeys,
        allCandidatesData,
        size
      );
      // setSearchValue("");
    } else {
      setSearch(false);
    }
  };
  return (
    <FilterBox>
      <div>
        {isYOE ? (
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="EQUALS">Equals</option>
            <option value="LESS_THAN"> Less Than</option>
            <option value="GREATER_THAN">Greater Than</option>
          </select>
        ) : (
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="CONTAINS">Contains</option>
            <option value="EQUALS">Equals</option>
            <option value="STARTSWITH">Starts With</option>
            <option value="ENDSWITH">Ends With</option>
          </select>
        )}
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={placeholder}
        />
        <button onClick={handleClick}>Apply</button>
      </div>
    </FilterBox>
  );
};
// Reusable Name Filter
export const NameFilter = (props) => {
  return (
    <Filter
      {...props}
      searchKeys={["firstName"]}
      placeholder="Full name search..."
      isYOE={false}
    />
  );
};
export const EmailFilter = (props) => {
  return (
    <Filter
      {...props}
      searchKeys={["email"]}
      placeholder="Email search..."
      isYOE={false}
    />
  );
};
export const YOEFilter = (props) => {
  return (
    <Filter
      {...props}
      searchKeys={["yoe"]}
      placeholder="year of experience search..."
      isYOE={true}
    />
  );
};
export const ContactFilter = (props) => {
  return (
    <Filter
      {...props}
      searchKeys={["contact"]}
      placeholder="contact search..."
      isYOE={false}
    />
  );
};
// Styled component for filter box
export const FilterBox = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width:200px;
  div {
    display: flex;
    flex-flow: column;
    gap: 8px;
    select, input, button {
      padding: 4px 8px;
      border: 1px solid #ccc;
      border-radius: 3px;s
      font-size: 14px;
      // width: 200px; 
    }
    button {
      background-color: #007bff;
      color: white;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
`;
